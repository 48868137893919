import React, { useEffect, useRef } from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import LogoutIcon from "@mui/icons-material/Logout";
import { drawerWidth } from "./Drawer";
import { store } from "../store";
import {
  logoutAction,
  updateSelectedCompanyAction,
} from "../redux/actions/login";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import ClearIcon from "@mui/icons-material/Clear";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import strings from "../i18n/language";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppBarComponent = ({ open, toggleDrawer }) => {
  const selectedCompany = useSelector((state) => state.login.selectedCompany);
  const user = useSelector((state) => state.user.user);
  const prevCompanyRef = useRef();
  const navigate = useNavigate();
  useEffect(() => {
    if (selectedCompany && prevCompanyRef.current !== selectedCompany) {
      toast.info(
        strings.formatString(strings.LABEL_CONNECTED_AS, selectedCompany.name)
      );
      prevCompanyRef.current = selectedCompany;
    }
  }, [selectedCompany]);
  return (
    <AppBar position="absolute" open={open}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: "36px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>

        {selectedCompany && (
          <>
            <FingerprintIcon style={{ marginRight: 10 }} />
            <Typography>{selectedCompany.name}</Typography>
            {user.admin && (
              <IconButton
                size="large"
                aria-label="show 2 new notifications"
                color="inherit"
                onClick={() => {
                  store.dispatch(updateSelectedCompanyAction(null));
                  navigate("/dashboard");
                }}
              >
                <ClearIcon />
              </IconButton>
            )}
          </>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          size="large"
          aria-label="show 2 new notifications"
          color="inherit"
          onClick={() => {
            store.dispatch(logoutAction());
          }}
        >
          <LogoutIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarComponent;
