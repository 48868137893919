import { COMPANY } from "../constants";

const initialState = {
  company: null,
  companies: [],
  isLoadingGetCompany: false,
  isLoadingGetCompanies: false,
  isLoadingCreateCompany: false,
  isLoadingUpdateCompany: false,
  isLoadingDeleteCompany: false,
  error: null,
};

const companyReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case COMPANY.CREATE_COMPANY:
      return {
        ...state,
        isLoadingCreateCompany: true,
      };
    case COMPANY.CREATE_COMPANY_FAILURE:
      return {
        ...state,
        isLoadingCreateCompany: false,
        error: action.payload,
      };
    case COMPANY.CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        isLoadingCreateCompany: false,
        company: action.payload,
        error: null,
      };
    case COMPANY.UPDATE_COMPANY:
      return {
        ...state,
        isLoadingUpdateCompany: true,
      };
    case COMPANY.UPDATE_COMPANY_FAILURE:
      return {
        ...state,
        isLoadingUpdateCompany: false,
        error: action.payload,
      };
    case COMPANY.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        isLoadingUpdateCompany: false,
        company: action.payload,
        error: null,
      };
    case COMPANY.DELETE_COMPANY:
      return {
        ...state,
        isLoadingDeleteCompany: true,
      };
    case COMPANY.DELETE_COMPANY_FAILURE:
      return {
        ...state,
        isLoadingDeleteCompany: false,
        error: action.payload,
      };
    case COMPANY.DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        isLoadingDeleteCompany: false,
        error: null,
      };
    case COMPANY.GET_COMPANIES:
      return {
        ...state,
        isLoadingGetCompanies: true,
      };
    case COMPANY.GET_COMPANIES_FAILURE:
      return {
        ...state,
        isLoadingGetCompanies: false,
        error: action.payload,
      };
    case COMPANY.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        isLoadingGetCompanies: false,
        companies: action.payload,
        error: null,
      };
    case COMPANY.GET_COMPANY:
      return {
        ...state,
        isLoadingGetCompany: true,
      };
    case COMPANY.GET_COMPANY_FAILURE:
      return {
        ...state,
        isLoadingGetCompany: false,
        error: action.payload,
      };
    case COMPANY.GET_COMPANY_SUCCESS:
      return {
        ...state,
        isLoadingGetCompany: false,
        company: action.payload,
        error: null,
      };
    case COMPANY.CLEAN_COMPANY:
      return {
        ...state,
        company: null,
      };
    default:
      return state;
  }
};

export default companyReducer;
