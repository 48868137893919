import { STATS } from "../constants";

const initialState = {
  stats: null,
  isLoadingStats: true,
  error: null,
};

const statsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case STATS.GET_STATS_USER:
      return {
        ...state,
        isLoadingStats: true,
      };
    case STATS.GET_STATS_USER_FAILURE:
      return {
        ...state,
        isLoadingStats: false,
        error: action.payload,
      };
    case STATS.GET_STATS_USER_SUCCESS:
      return {
        ...state,
        isLoadingStats: false,
        stats: action.payload,
        error: null,
      };
    case STATS.GET_STATS_COMPANYc:
      return {
        ...state,
        isLoadingStats: true,
      };
    case STATS.GET_STATS_COMPANYc_FAILURE:
      return {
        ...state,
        isLoadingStats: false,
        error: action.payload,
      };
    case STATS.GET_STATS_COMPANYc_SUCCESS:
      return {
        ...state,
        isLoadingStats: false,
        stats: action.payload,
        error: null,
      };
    case STATS.CLEAN_STATS:
      return {
        ...state,
      };
    default:
      return initialState;
  }
};

export default statsReducer;
