import { LOGIN } from "../constants";

const initialState = {
  tokenAccess: null,
  selectedCompany: null,
  isLoadingLogin: false,
  error: null,
};

const loginReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGIN.LOGIN_REQUEST:
      return {
        ...state,
        tokenAccess: null,
        isLoadingLogin: true,
      };
    case LOGIN.LOGIN_FAILURE:
      return {
        ...state,
        isLoadingLogin: false,
        error: action.payload,
      };
    case LOGIN.LOGIN_SUCCESS:
      return {
        ...state,
        isLoadingLogin: false,
        tokenAccess: action.payload.tokenAccess,
        error: null,
      };
    case LOGIN.LOGOUT:
      return {
        isLoadingLogin: false,
        tokenAccess: null,
        error: null,
      };
    case LOGIN.UPDATE_USER:
      return {
        ...state,
        isLoadingUpdateUser: true,
      };
    case LOGIN.UPDATE_USER_FAILURE:
      return {
        ...state,
        isLoadingUpdateUser: false,
        error: action.payload,
      };
    case LOGIN.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoadingUpdateUser: false,
        error: null,
      };
    case LOGIN.UPDATE_TOKEN:
      return {
        ...state,
        tokenAccess: action.payload,
      };
    case LOGIN.UPDATE_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload,
      };
    case LOGIN.CLEAN_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default loginReducer;
