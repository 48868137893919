import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import CustomTextField from "../form/CustomTextField";
import strings from "../i18n/language";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Fab from "@mui/material/Fab";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import {
  resetUserAction,
  definePasswordAction,
  cleanErrorAction,
  resetUserPasswordAction,
} from "../redux/actions/user";
import { useDispatch, useSelector } from "react-redux";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [honeyPot, setHoneyPot] = useState("");
  const [email, setEmail] = useState("");
  const [answer, setAnswer] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [question, setQuestion] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const theme = useTheme();
  const { token } = useParams();
  const [errorMail, setErrorMail] = useState(false);
  const [errorAnswer, setErrorAnswer] = useState(false);
  const [errorPassword1, setErrorPassword1] = useState(false);
  const [errorPassword2, setErrorPassword2] = useState(false);
  const resetPassword = useSelector((state) => state.user.resetPassword);
  const definePassword = useSelector((state) => state.user.definePassword);
  const error = useSelector((state) => state.user.error);

  useEffect(() => {
    if (error) {
      if (error === "MAIL_NOT_FOUND") {
        dispatch(cleanErrorAction());
        generateQuestion();
        toast.error(strings.LABEL_RESET_MAIL_NOT_FOUND);
      }
      if (error === "TOKEN_NOT_FOUND" || error === "TOKEN_EXPIRED") {
        dispatch(cleanErrorAction());
        toast.error(strings.LABEL_RESET_GLOBAL_ERROR);
      }
    }
  }, [error, dispatch, navigate]);

  useEffect(() => {
    if (resetPassword) {
      setEmail("");
      setAnswer("");
      setErrorMail(false);
      setErrorAnswer(false);
      toast.success(strings.LABEL_RESET_PASSWORD_RESET_DONE, {
        onClose: () => {
          dispatch(resetUserAction());
          navigate("/");
        },
      });
    }
  }, [resetPassword, navigate, dispatch]);

  useEffect(() => {
    if (definePassword) {
      setPassword1("");
      setPassword2("");
      setErrorPassword1(false);
      setErrorPassword2(false);
      toast.success(strings.LABEL_RESET_DEFINE_DONE, {
        onClose: () => {
          dispatch(resetUserAction());
          navigate("/");
        },
      });
    }
  }, [definePassword, navigate, dispatch]);

  const generateQuestion = () => {
    const random = Math.floor(Math.random() * 2);
    let questionText = "";
    let answer = "";
    if (random === 0) {
      const num1 = Math.floor(Math.random() * 10);
      const num2 = Math.floor(Math.random() * 10);
      questionText = strings.formatString(
        strings.LABEL_RESET_QUESTION_SUM,
        num1,
        num2
      );
      answer = num1 + num2;
    } else {
      const num1 = Math.floor(Math.random() * 20);
      const num2 = Math.floor(Math.random() * 20);
      questionText = strings.formatString(
        strings.LABEL_RESET_QUESTION_COMPARE,
        num1,
        num2
      );
      answer = num1 > num2 ? num1.toString() : num2.toString();
    }
    setQuestion(questionText);
    setCorrectAnswer(answer);
  };

  useEffect(() => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*[\W_]).{6,}$/;
    if (password1 && passwordRegex.test(password1)) setErrorPassword1(false);
    else setErrorPassword1(true);
  }, [password1]);

  useEffect(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (email && emailRegex.test(email)) setErrorMail(false);
    else setErrorMail(true);
  }, [email]);

  useEffect(() => {
    if (answer && parseInt(correctAnswer) === parseInt(answer))
      setErrorAnswer(false);
    else setErrorAnswer(true);
  }, [answer, correctAnswer]);

  const sendResetPassword = (event) => {
    event.preventDefault();
    if (honeyPot) return null;
    if (!errorAnswer && !errorMail) {
      dispatch(
        resetUserPasswordAction({
          email: email,
        })
      );
    } else toast.error(strings.LABEL_RESET_ERROR_VERIFY);
  };

  const sendDefinePassword = (event) => {
    event.preventDefault();
    if (!errorPassword1 && !errorPassword2) {
      dispatch(
        definePasswordAction({
          token: token,
          password: password1,
        })
      );
    } else toast.error(strings.LABEL_RESET_ERROR_VERIFY);
  };

  useEffect(() => {
    generateQuestion();
  }, []);

  useEffect(() => {
    if (password1 && password2 && password1 === password2)
      setErrorPassword2(false);
    else setErrorPassword2(true);
  }, [password1, password2]);

  return (
    <div>
      <ToastContainer position="bottom-right" />
      <Container maxWidth="lg" sx={{ mt: 8, mb: 4 }}>
        <Grid
          container
          spacing={3}
          sx={{
            justifyContent: "center",
          }}
        >
          <>
            <Fab
              color="primary"
              aria-label="back"
              style={{
                marginRight: 10,
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              <ArrowBackIcon />
            </Fab>
            <Paper
              sx={{
                p: 2,
                width: "60%",
                backgroundColor: theme.palette.primary.main,
              }}
            >
              {!token ? (
                <Grid margin="normal" mt={2} p={4}>
                  <form noValidate id="resetForm" onSubmit={sendResetPassword}>
                    <Grid item display="flex">
                      <Typography variant="h5" align="center" color="white">
                        {strings.LABEL_RESET_PASSWORD_TITLE}
                      </Typography>
                    </Grid>
                    <Grid item display="flex" color="white">
                      <Typography>
                        {strings.LABEL_RESET_PASSWORD_CONTENT}
                      </Typography>
                    </Grid>
                    <Grid item mt={2} display="flex">
                      <CustomTextField
                        label={strings.LABEL_INPUT_MAIL}
                        type="email"
                        value={email}
                        onChange={setEmail}
                        fullWidth
                        required
                      />
                    </Grid>
                    {email && (
                      <Grid item xs={8} mt={1}>
                        <Typography
                          color={errorMail ? "error" : "success.main"}
                        >
                          {strings.LABEL_RESET_PASSWORD_MAIL_ERROR}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item mt={2} display="flex">
                      <Typography color="white">
                        {strings.LABEL_RESET_PASSWORD_RESOLVE}
                      </Typography>
                    </Grid>
                    <Grid item mt={2} display="flex">
                      <Typography sx={{ fontWeight: "bold" }} color="white">
                        {question}
                      </Typography>
                    </Grid>
                    <Grid item mt={2} display="flex">
                      <CustomTextField
                        label={strings.LABEL_INPUT_ANSWER}
                        type="answer"
                        value={answer}
                        onChange={setAnswer}
                        fullWidth
                        required
                      />
                    </Grid>
                    {answer && (
                      <Grid item xs={8} mt={1}>
                        <Typography
                          color={errorAnswer ? "error" : "success.main"}
                        >
                          {strings.LABEL_RESET_PASSWORD_ANSWER_ERROR}
                        </Typography>
                      </Grid>
                    )}
                    <input
                      type="text"
                      name="honeypot"
                      value={honeyPot}
                      onChange={setHoneyPot}
                      style={{ display: "none" }}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 2,
                        color: "white",
                      }}
                      color="secondary"
                    >
                      {strings.LABEL_BUTTON_RETRIEVE_PASSWORD}
                    </Button>
                  </form>
                </Grid>
              ) : (
                <Grid margin="normal" mt={2} p={4}>
                  <form noValidate onSubmit={sendDefinePassword}>
                    <Grid item display="flex">
                      <Typography variant="h5" align="center" color="white">
                        {strings.LABEL_RESET_DEFINE_PASSWORD_TITLE}
                      </Typography>
                    </Grid>
                    <Grid item display="flex">
                      <Typography color="white">
                        {strings.LABEL_RESET_DEFINE_PASSWORD_CONTENT}
                      </Typography>
                    </Grid>
                    <Grid item mt={2} display="flex">
                      <CustomTextField
                        label={strings.LABEL_RESET_PASSWORD_1}
                        type="password"
                        value={password1}
                        onChange={setPassword1}
                        fullWidth
                        required
                      />
                      <Tooltip title={strings.LABEL_RESET_PASSWORD_TOOLTIP_1}>
                        <IconButton style={{ color: "white" }}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    {password1 && (
                      <Grid item xs={8} mt={1}>
                        <Typography
                          color={errorPassword1 ? "error" : "success.main"}
                        >
                          {strings.LABEL_RESET_PASSWORD_1_ERROR}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item mt={2} display="flex">
                      <CustomTextField
                        label={strings.LABEL_RESET_PASSWORD_2}
                        type="password"
                        value={password2}
                        onChange={setPassword2}
                        fullWidth
                        required
                      />
                      <Tooltip title={strings.LABEL_RESET_PASSWORD_TOOLTIP_2}>
                        <IconButton style={{ color: "white" }}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    {password2 && (
                      <Grid item xs={8} mt={1}>
                        <Typography
                          color={errorPassword2 ? "error" : "success.main"}
                        >
                          {strings.LABEL_RESET_PASSWORD_2_ERROR}
                        </Typography>
                      </Grid>
                    )}
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 2, mb: 2, color: "white" }}
                      color="secondary"
                    >
                      {strings.LABEL_BUTTON_RETRIEVE_PASSWORD}
                    </Button>
                  </form>
                </Grid>
              )}
            </Paper>
          </>
        </Grid>
      </Container>
    </div>
  );
};

export default ForgotPassword;
