import React, { useEffect } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Rating,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getFeedbacksAction } from "../redux/actions/feedback";
import moment from "moment";
import strings from "../i18n/language";
import LinearProgress from "@mui/material/LinearProgress";

const Feedbacks = () => {
  const feedbacks = useSelector((state) => state.feedback.feedbacks);
  const isLoadingFeedbacks = useSelector(
    (state) => state.feedback.isLoadingFeedbacks
  );
  const selectedCompany = useSelector((state) => state.login.selectedCompany);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFeedbacksAction(selectedCompany.id));
  }, [dispatch, selectedCompany]);

  useEffect(() => {
    if (!feedbacks) {
      dispatch(getFeedbacksAction(selectedCompany.id));
    }
  }, [feedbacks, dispatch, selectedCompany]);

  return (
    <Paper sx={{ p: 2 }}>
      {!feedbacks ||
        (isLoadingFeedbacks && (
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              width: "100%",
            }}
          />
        ))}
      {feedbacks && (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="Feedbacks table">
            <TableHead>
              <TableRow>
                <TableCell>{strings.LABEL_FEEDBACKS_HEADER_USER}</TableCell>
                <TableCell>{strings.LABEL_FEEDBACKS_HEADER_NOTE}</TableCell>
                <TableCell>{strings.LABEL_FEEDBACKS_HEADER_MESSAGE}</TableCell>
                <TableCell>{strings.LABEL_FEEDBACKS_HEADER_DATE}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!feedbacks.length && (
                <TableRow>
                  <TableCell style={{ textAlign: "center" }} colSpan={4}>
                    {strings.LABEL_NO_RESULTS}
                  </TableCell>
                </TableRow>
              )}
              {feedbacks.map((feedback) => (
                <TableRow key={feedback.id}>
                  <TableCell component="th" scope="row">
                    {feedback.User.displayName}
                  </TableCell>
                  <TableCell>
                    <Rating value={feedback.note} readOnly />
                  </TableCell>
                  <TableCell>{feedback.message}</TableCell>
                  <TableCell>{moment(feedback.date).format("LL")}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};

export default Feedbacks;
