import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Card, CardContent } from "@mui/material";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  ResponsiveContainer,
  Tooltip,
  BarChart,
  XAxis,
  YAxis,
  Bar,
} from "recharts";
import { getStatsUserAction } from "../redux/actions/stats";
import { useDispatch, useSelector } from "react-redux";
import strings from "../i18n/language";
import InfoIcon from "@mui/icons-material/Info";

let ageRanges = [
  { name: "unknown", min: null, max: null, value: 0 },
  { name: "<18", min: 0, max: 18, value: 0 },
  { name: "18-24", min: 18, max: 24, value: 0 },
  { name: "25-34", min: 25, max: 34, value: 0 },
  { name: "35-44", min: 35, max: 44, value: 0 },
  { name: "45-54", min: 45, max: 54, value: 0 },
  { name: "55-64", min: 55, max: 64, value: 0 },
  { name: ">64", min: 65, max: Infinity, value: 0 },
];

const SIZE_LABELS = 14;

const DashboardUser = () => {
  const dispatch = useDispatch();
  const stats = useSelector((state) => state.stats.stats);
  const [pointsData, setPointsData] = useState([]);
  const [genderDistribution, setGenderDistribution] = useState([]);
  const [ageDistribution, setAgeDistribution] = useState([]);
  const [giftsData, setGiftsData] = useState([]);

  useEffect(() => {
    if (stats) {
      let pointsDataTmp = [];
      //points given by week
      Object.entries(stats.pointsGivenByWeek).map(([key, value]) => {
        pointsDataTmp.push({
          week: strings.LABEL_DASHBOARD_W + key,
          points: value,
        });
      });
      setPointsData(pointsDataTmp);
      //gender distribution
      let totalGender =
        parseInt(stats.genderDistribution.man) +
        parseInt(stats.genderDistribution.unknown) +
        parseInt(stats.genderDistribution.woman);
      if (totalGender)
        setGenderDistribution([
          {
            name:
              strings.LABEL_DASHBOARD_MAN +
              " (" +
              parseInt((stats.genderDistribution.man * 100) / totalGender) +
              "%)",
            value: (stats.genderDistribution.man * 100) / totalGender,
          },
          {
            name:
              strings.LABEL_DASHBOARD_NOT_DEFINED +
              " (" +
              parseInt((stats.genderDistribution.unknown * 100) / totalGender) +
              "%)",
            value: (stats.genderDistribution.unknown * 100) / totalGender,
          },
          {
            name:
              strings.LABEL_DASHBOARD_WOMAN +
              " (" +
              parseInt((stats.genderDistribution.woman * 100) / totalGender) +
              "%)",
            value: stats.genderDistribution.woman + 100 / totalGender,
          },
        ]);
      //age distribution
      let totalAges = 0;
      Object.entries(ageRanges).map(([key, value]) => {
        if (ageRanges[key].value) return null;
        if (value.name === "unknown") {
          ageRanges[key].value = stats.ageDistribution.unknown;
          totalAges += stats.ageDistribution.unknown;
        } else {
          Object.entries(stats.ageDistribution).map(([age, count]) => {
            if (value.min <= age && age <= value.max) {
              ageRanges[key].value += count;
              totalAges += count;
            }
          });
        }
      });

      let resultAges = ageRanges.filter((item) => item.value);
      setAgeDistribution([]);
      Object.keys(resultAges).forEach(function (key, index) {
        if (!resultAges[key].name.includes("%")) {
          resultAges[index].name = `${resultAges[index].name} ${parseInt(
            (resultAges[index].value * 100) / totalAges
          )}%`;
        }
      });
      setAgeDistribution(resultAges);
      //giftsGivenByWeek
      let giftsGivenByWeek = [];
      Object.entries(stats.giftsGivenByWeek).map(([key, value]) => {
        giftsGivenByWeek.push({
          week: strings.LABEL_DASHBOARD_W + key,
          gift5: value.gift5,
          gift10: value.gift10,
        });
      });
      setGiftsData(giftsGivenByWeek);
    } else dispatch(getStatsUserAction());
  }, [stats, dispatch]);

  if (!stats) return null;
  else
    return (
      <Box sx={{ flexGrow: 1, mx: 2 }}>
        <Grid container spacing={3}>
          {/* Number of Active Client Card */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  {strings.LABEL_DASHBOARD_ACTIVE_CLIENTS}
                </Typography>
                <Typography variant="h3" component="div">
                  {stats.activeClientsLastMonth}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <InfoIcon fontSize="small" />
                  <Typography variant="body2" component="div">
                    {strings.LABEL_DASHBOARD_ACTIVE_CLIENTS_INFO}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          {/* Number of Clients Card */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  {strings.LABEL_DASHBOARD_NEW_CLIENTS}
                </Typography>
                <Typography variant="h3" component="div">
                  {stats.newClientsLastMonth}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <InfoIcon fontSize="small" />
                  <Typography variant="body2" component="div">
                    {strings.LABEL_DASHBOARD_NEW_CLIENTS_INFO}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          {/* Number of Clients Card */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  {strings.LABEL_DASHBOARD_NUMBER_OF_CLIENTS}
                </Typography>
                <Typography variant="h3" component="div">
                  {stats.numberOfClients}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Points Given Card */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  {strings.LABEL_DASHBOARD_POINTS_GIVEN}
                </Typography>
                <div sx={{ flexDirection: "row", display: "flex" }}>
                  <Typography variant="h3" component="div">
                    {stats.pointGivenAll}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>

          {/* Age Distribution Card */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {strings.LABEL_DASHBOARD_AGE_DISTRIBUTION}
                  </Typography>
                  <ResponsiveContainer width="100%" height={400}>
                    {ageDistribution.length ? (
                      <RadarChart outerRadius={150} data={ageDistribution}>
                        <PolarGrid />
                        <PolarAngleAxis
                          style={{ fontSize: SIZE_LABELS }}
                          dataKey="name"
                        />
                        <Radar
                          name="Distribution"
                          dataKey="value"
                          fill="#5DADE2"
                          fillOpacity={0.6}
                        />
                      </RadarChart>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h6" align="center">
                          {strings.LABEL_DASHBOARD_NO_DATA}
                        </Typography>
                      </div>
                    )}
                  </ResponsiveContainer>
                </CardContent>
              </CardContent>
            </Card>
          </Grid>

          {/* Gender Distribution Card */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {strings.LABEL_DASHBOARD_GENDER_DISTRIBUTION}
                  </Typography>
                  <ResponsiveContainer width="100%" height={400}>
                    {genderDistribution.length ? (
                      <RadarChart outerRadius={120} data={genderDistribution}>
                        <PolarGrid />
                        <PolarAngleAxis
                          dataKey="name"
                          style={{ fontSize: SIZE_LABELS }}
                        />
                        <Radar
                          name="Distribution"
                          dataKey="value"
                          fill="#5DADE2"
                          fillOpacity={0.6}
                        />
                      </RadarChart>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h6" align="center">
                          {strings.LABEL_DASHBOARD_NO_DATA}
                        </Typography>
                      </div>
                    )}
                  </ResponsiveContainer>
                </CardContent>
              </CardContent>
            </Card>
          </Grid>

          {/* Points Given Card */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  {strings.LABEL_DASHBOARD_POINTS_GIVEN_W}
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  {pointsData.length ? (
                    <BarChart data={pointsData}>
                      <XAxis dataKey="week" style={{ fontSize: SIZE_LABELS }} />
                      <YAxis allowDecimals={false} />
                      <Tooltip cursor={{ fill: "transparent" }} />
                      <Bar
                        dataKey="points"
                        fill="#5DADE2"
                        fillOpacity={0.6}
                        barSize={50}
                        onMouseEnter={() => {}} // Disable hover effect
                        onMouseLeave={() => {}} // Disable hover effect
                      />
                    </BarChart>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h6" align="center">
                        {strings.LABEL_DASHBOARD_NO_DATA}
                      </Typography>
                    </div>
                  )}
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>

          {/* Promotions Given Card */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  {strings.LABEL_DASHBOARD_GIFTS_GIVEN_W}
                </Typography>
                <ResponsiveContainer width="100%" height={300} style={{}}>
                  {giftsData.length ? (
                    <BarChart data={giftsData}>
                      <XAxis dataKey="week" style={{ fontSize: SIZE_LABELS }} />
                      <YAxis allowDecimals={false} />
                      <Tooltip cursor={{ fill: "transparent" }} />
                      <Bar
                        stackId="a"
                        dataKey="gift5"
                        fill="#5DADE2"
                        fillOpacity={0.5}
                        barSize={50}
                        onMouseEnter={() => {}}
                        onMouseLeave={() => {}}
                      />
                      <Bar
                        stackId="a"
                        dataKey="gift10"
                        fill="#5DADE2"
                        fillOpacity={0.8}
                        barSize={50}
                        onMouseEnter={() => {}}
                        onMouseLeave={() => {}}
                      />
                    </BarChart>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h6" align="center">
                        {strings.LABEL_DASHBOARD_NO_DATA}
                      </Typography>
                    </div>
                  )}
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    );
};

export default DashboardUser;
