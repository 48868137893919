import { ADDRESS } from "../constants";
import { instance } from "../../axios";

let getAddressAction = (id) => {
  return (dispatch) => {
    dispatch({
      type: ADDRESS.GET_ADDRESS,
    });
    instance
      .get("/address/" + id)
      .then((results) => {
        dispatch({
          type: ADDRESS.GET_ADDRESS_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ADDRESS.GET_ADDRESS_FAILURE,
          payload: error,
        });
      });
  };
};

let getAddressesAction = (companyId) => {
  return (dispatch) => {
    dispatch({
      type: ADDRESS.GET_ADDRESSES,
    });
    instance
      .get("/address?company_id=" + companyId)
      .then((results) => {
        dispatch({
          type: ADDRESS.GET_ADDRESSES_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ADDRESS.GET_ADDRESSES_FAILURE,
          payload: error,
        });
      });
  };
};

let createAddressesAction = (body) => {
  return (dispatch) => {
    dispatch({
      type: ADDRESS.CREATE_ADDRESS,
    });
    instance
      .post("/address", body)
      .then((results) => {
        dispatch({
          type: ADDRESS.CREATE_ADDRESS_SUCCESS,
          payload: results.data,
        });
        dispatch(getAddressesAction(results.data.company_id));
      })
      .catch((error) => {
        dispatch({
          type: ADDRESS.CREATE_ADDRESS_FAILURE,
          payload: error,
        });
      });
  };
};

let updateAddressesAction = (address) => {
  return (dispatch) => {
    dispatch({
      type: ADDRESS.UPDATE_ADDRESS,
    });
    instance
      .post("/address/" + address.id, address)
      .then((results) => {
        dispatch({
          type: ADDRESS.UPDATE_ADDRESS_SUCCESS,
          payload: results.data,
        });
        dispatch(getAddressesAction(results.data.company_id));
      })
      .catch((error) => {
        dispatch({
          type: ADDRESS.UPDATE_ADDRESS_FAILURE,
          payload: error,
        });
      });
  };
};

let deleteAddressAction = (id) => {
  return (dispatch) => {
    dispatch({
      type: ADDRESS.DELETE_ADDRESS,
    });
    instance
      .delete("/address/" + id)
      .then((results) => {
        dispatch({
          type: ADDRESS.DELETE_ADDRESS_SUCCESS,
          payload: results.data,
        });
        dispatch(getAddressesAction(results.data.company_id));
      })
      .catch((error) => {
        dispatch({
          type: ADDRESS.DELETE_ADDRESS_FAILURE,
          payload: error,
        });
      });
  };
};

let cleanAddressAction = () => {
  return (dispatch) => {
    dispatch({
      type: ADDRESS.CLEAN_ADDRESS,
    });
  };
};

export {
  getAddressesAction,
  updateAddressesAction,
  createAddressesAction,
  cleanAddressAction,
  deleteAddressAction,
  getAddressAction,
};
