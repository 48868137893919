import { STATS } from "../constants";
import { instance } from "../../axios";

let getStatsCompanyAction = () => {
  return (dispatch) => {
    dispatch({
      type: STATS.GET_STATS_USER,
    });
    instance
      .get("/stats/company")
      .then((results) => {
        dispatch({
          type: STATS.GET_STATS_USER_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: STATS.GET_STATS_USER_FAILURE,
          payload: error,
        });
      });
  };
};

let getStatsUserAction = () => {
  return (dispatch) => {
    dispatch({
      type: STATS.GET_STATS_USER,
    });
    instance
      .get("/stats/user")
      .then((results) => {
        dispatch({
          type: STATS.GET_STATS_USER_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: STATS.GET_STATS_USER_FAILURE,
          payload: error,
        });
      });
  };
};

let cleanStatsAction = () => {
  return (dispatch) => {
    dispatch({
      type: STATS.CLEAN_STATS,
    });
  };
};

export { getStatsUserAction, cleanStatsAction, getStatsCompanyAction };
