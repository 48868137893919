import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Button } from "@mui/material";
import strings from "../i18n/language";
import React, { useState, useRef, useEffect } from "react";

import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import DeleteIcon from "@mui/icons-material/Delete";

const RATIO = 2;
const MIN_IMAGE_WIDTH = 600;
const MIN_IMAGE_HEIGHT = 300;

const canvasPreview = (image, canvas, crop, scale = 1) => {
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("No 2d context");
  }
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;
  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);
  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";
  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;
  ctx.save();
  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.scale(scale, scale);
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );
  ctx.restore();
};

const useDebounceEffect = (fn, waitTime, deps) => {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, deps);
};

function centerAspectCrop(mediaWidth, mediaHeight) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 80,
      },
      RATIO,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const CompanyPicture = ({ open, setOpen, title, setPicture }) => {
  const [imgSrc, setImgSrc] = useState("");
  const fileInputRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const hiddenAnchorRef = useRef(null);
  const [crop, setCrop] = useState();
  const [error, setError] = useState();
  const [completedCrop, setCompletedCrop] = useState();

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setError(null);
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    const { width, height, naturalHeight, naturalWidth } = e.currentTarget;
    if (naturalWidth < MIN_IMAGE_WIDTH || naturalHeight < MIN_IMAGE_HEIGHT) {
      setError(
        strings.formatString(
          strings.LABEL_COMPANIES_ERROR_IMAGE_SIZE,
          MIN_IMAGE_WIDTH,
          MIN_IMAGE_HEIGHT,
          naturalWidth,
          naturalHeight
        )
      );

      setImgSrc("");
      return;
    }
    setCrop(centerAspectCrop(width, height));
  }

  const save = async () => {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error("Crop canvas does not exist");
    }
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );
    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }
    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );
    const blob = await offscreen.convertToBlob({
      type: "image/jpg",
      quality: 0.8,
    });
    setPicture(blob);
    setOpen(false);
    fileInputRef.current.value = "";
    setImgSrc(null);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1,
          0
        );
      }
    },
    100,
    [completedCrop, 1, 0]
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div className="Crop-Controls">
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={onSelectFile}
          />
          <IconButton
            aria-label="delete"
            onClick={() => {
              fileInputRef.current.value = "";
              setImgSrc(null);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
        <div
          style={{
            height: 400,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!imgSrc && !error && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {strings.formatString(
                strings.LABEL_COMPANIES_PICTURE_MINIMUM,
                MIN_IMAGE_WIDTH,
                MIN_IMAGE_HEIGHT
              )}
            </div>
          )}
          {error && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <SentimentVeryDissatisfiedIcon
                style={{ fontSize: 100, marginBottom: 10 }}
              ></SentimentVeryDissatisfiedIcon>
              {error}
            </div>
          )}
          {!!imgSrc && (
            <>
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={RATIO}
                minHeight={150}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
              {!!completedCrop && (
                <>
                  <div>
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        display: "none",
                      }}
                    />
                  </div>
                  <div>
                    <a
                      href="#hidden"
                      ref={hiddenAnchorRef}
                      download
                      style={{
                        position: "absolute",
                        top: "-200vh",
                        visibility: "hidden",
                      }}
                    >
                      Hidden download
                    </a>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          {strings.LABEL_BUTTON_CANCEL}
        </Button>
        <Button
          onClick={() => {
            save();
          }}
        >
          {" "}
          {strings.LABEL_BUTTON_SAVE}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompanyPicture;
