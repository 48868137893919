import React, { useState, useEffect } from "react";
import { Tooltip, Grid, Paper, Typography, IconButton } from "@mui/material";
import Fab from "@mui/material/Fab";
import Chip from "@mui/material/Chip";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import CustomTextField from "../../form/CustomTextField";
import CustomSelectField from "../../form/CustomSelectField";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@mui/material/styles";
import {
  createAddressesAction,
  updateAddressesAction,
} from "../../redux/actions/address";
import strings from "../../i18n/language";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import _ from "lodash";

const listDays = [
  { value: 1, label: strings.LABEL_SETTINGS_MONDAY },
  { value: 2, label: strings.LABEL_SETTINGS_TUESDAY },
  { value: 3, label: strings.LABEL_SETTINGS_WEDNESDAY },
  { value: 4, label: strings.LABEL_SETTINGS_THURSDAY },
  { value: 5, label: strings.LABEL_SETTINGS_FRIDAY },
  { value: 6, label: strings.LABEL_SETTINGS_SATURDAY },
  { value: 7, label: strings.LABEL_SETTINGS_SUNDAY },
];

const listHours = [
  { value: 0, label: "00:00" },
  { value: 0.5, label: "00:30" },
  { value: 1, label: "01:00" },
  { value: 1.5, label: "01:30" },
  { value: 2, label: "02:00" },
  { value: 2.5, label: "02:30" },
  { value: 3, label: "03:00" },
  { value: 3.5, label: "03:30" },
  { value: 4, label: "04:00" },
  { value: 4.5, label: "04:30" },
  { value: 5, label: "05:00" },
  { value: 5.5, label: "05:30" },
  { value: 6, label: "06:00" },
  { value: 6.5, label: "06:30" },
  { value: 7, label: "07:00" },
  { value: 7.5, label: "07:30" },
  { value: 8, label: "08:00" },
  { value: 8.5, label: "08:30" },
  { value: 9, label: "09:00" },
  { value: 9.5, label: "09:30" },
  { value: 10, label: "10:00" },
  { value: 10.5, label: "10:30" },
  { value: 11, label: "11:00" },
  { value: 11.5, label: "11:30" },
  { value: 12, label: "12:00" },
  { value: 12.5, label: "12:30" },
  { value: 13, label: "13:00" },
  { value: 13.5, label: "13:30" },
  { value: 14, label: "14:00" },
  { value: 14.5, label: "14:30" },
  { value: 15, label: "15:00" },
  { value: 15.5, label: "15:30" },
  { value: 16, label: "16:00" },
  { value: 16.5, label: "16:30" },
  { value: 17, label: "17:00" },
  { value: 17.5, label: "17:30" },
  { value: 18, label: "18:00" },
  { value: 18.5, label: "18:30" },
  { value: 19, label: "19:00" },
  { value: 19.5, label: "19:30" },
  { value: 20, label: "20:00" },
  { value: 20.5, label: "20:30" },
  { value: 21, label: "21:00" },
  { value: 21.5, label: "21:30" },
  { value: 22, label: "22:00" },
  { value: 22.5, label: "22:30" },
  { value: 23, label: "23:00" },
  { value: 23.5, label: "23:30" },
];

const LocalsEdit = () => {
  const navigate = useNavigate();
  const [name, setName] = useState(null);
  const [errorName, setErrorName] = useState(null);
  const [addressName, setAddressName] = useState(null);
  const [errorAddressName, setErrorAddressName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [errorPhone, setErrorPhone] = useState(null);
  const [addressId, setAddressId] = useState(null);
  const [errorAddressId, setErrorAddressId] = useState(null);
  const address = useSelector((state) => state.address.address);
  const selectedCompany = useSelector((state) => state.login.selectedCompany);
  const [opening, setOpening] = useState({
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
  });
  const [valid, setValid] = useState(false);
  const theme = useTheme();
  const [day, setDay] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(1);
  const dispatch = useDispatch();
  const dayjs = require("dayjs");

  const deleteRange = (index, day) => {
    const cloneConfiguration = _.cloneDeep(opening);
    _.pullAt(cloneConfiguration[day], index);
    setOpening(cloneConfiguration);
    // setDirty(true);*/
  };
  /*

dayjs()
              .startOf("week")
              .add(1, "day")
              .add(day - 2, "day")
              .format("dddd")*/
  const addRange = () => {
    let error = false;
    if (from >= to && to !== 0)
      error = strings.ERROR_SETTINGS_BEGIN_HIGHER_END_HOUR;
    if (!error)
      for (let i = 1; i < opening[day].length; i++) {
        if (from >= opening[day][i].open && from <= opening[day][i].close)
          error = strings.formatString(
            strings.ERROR_SETTINGS_BEGIN_OVERWRITE_RANGE,
            day
          );
      }
    if (!error)
      for (let i = 1; i < opening[day].length; i++) {
        if (to >= opening[day][i].open && to <= opening[day][i].close)
          error = strings.formatString(
            strings.ERROR_SETTINGS_TO_OVERWRITE_RANGE,
            day
          );
      }
    if (!error)
      for (let i = 1; i < opening[day].length; i++) {
        if (from < opening[day][i].open && to > opening[day][i].close)
          error = strings.formatString(
            strings.ERROR_SETTINGS_RANGE_IN_RANGE,
            day
          );
      }
    if (!error) {
      let diff = to - from;
      if (diff < 1) error = strings.ERROR_RANGE_LEAST_HOUR;
      if (diff % 1 !== 0)
        strings.formatString(strings.ERROR_RANGE_HALF_HOUR, diff);
    }
    if (false) toast.error(error);
    else {
      const cloneOpening = _.cloneDeep(opening);
      cloneOpening[day].push({
        open: from,
        close: to,
      });
      cloneOpening[day] = _.sortBy(cloneOpening[day], "open");
      setOpening(cloneOpening);
      // setDirty(true);
    }
  };

  useEffect(() => {
    if (opening) {
      console.log(opening);
    }
  }, [opening]);

  useEffect(() => {
    if (address) {
      setName(address.label);
      setAddressName(address.address);
      setPhone(address.phoneNumber);
      setAddressId(address.addressId);
      console.log(address.opening);
      if (address.opening) setOpening(JSON.parse(address.opening));
    }
  }, [address]);

  useEffect(() => {
    if (errorName || errorAddressName || errorPhone || errorAddressId) {
      setValid(false);
    } else setValid(true);
  }, [errorName, errorAddressName, errorPhone, errorAddressId]);

  useEffect(() => {
    const alphanumericRegex = /^[a-z0-9\sáéíóúñüäöüß.,;:!?'"\-()%]+$/i;
    if (name && name.length > 4 && name.length < 30) {
      if (alphanumericRegex.test(name)) {
        setErrorName(false);
      } else {
        setErrorName(true);
      }
    } else setErrorName(true);
  }, [name]);

  useEffect(() => {
    const alphanumericRegex = /^[a-z0-9\sáéíóúñüäöüß.,;:!?'"\-()%]+$/i;
    if (addressName && addressName.length > 4 && addressName.length < 60) {
      if (alphanumericRegex.test(addressName)) {
        setErrorAddressName(false);
      } else {
        setErrorAddressName(true);
      }
    } else setErrorAddressName(true);
  }, [addressName]);

  useEffect(() => {
    const alphanumericRegex = /^(tel:|whatsapp:)\+?[0-9]{1,15}$/i;
    if (phone) {
      if (alphanumericRegex.test(phone)) {
        setErrorPhone(false);
      } else {
        setErrorPhone(true);
      }
    } else setErrorPhone(true);
  }, [phone]);

  useEffect(() => {
    const alphanumericRegex = /^[A-Za-z0-9]{16,20}$/i;
    if (addressId) {
      if (alphanumericRegex.test(addressId)) {
        setErrorAddressId(false);
      } else {
        setErrorAddressId(true);
      }
    } else setErrorAddressId(true);
  }, [addressId]);

  const save = () => {
    if (address && address.id)
      dispatch(
        updateAddressesAction({
          ...address,
          ...{
            label: name,
            address: addressName,
            phoneNumber: phone,
            addressId: addressId,
            opening: opening,
          },
        })
      );
    else
      dispatch(
        createAddressesAction({
          label: name,
          address: addressName,
          phoneNumber: phone,
          addressId: addressId,
          company_id: selectedCompany.id,
          opening: opening,
        })
      );
    navigate("/locals");
  };

  return (
    <>
      <ToastContainer position="bottom-right" />
      <Fab
        color="primary"
        aria-label="back"
        style={{
          marginRight: 10,
        }}
        onClick={() => {
          navigate("/locals");
        }}
      >
        <ArrowBackIcon />
      </Fab>
      <Paper
        sx={{ p: 2, width: "80%", backgroundColor: theme.palette.primary.main }}
      >
        <Grid container justifyContent="center" margin="normal" mt={2}>
          <Grid item xs={8} mt={2} display="flex">
            <CustomTextField
              margin="normal"
              fullWidth
              id="label"
              label={strings.LABEL_LOCALS_NAME}
              name="label"
              value={name}
              onChange={setName}
              autoFocus
            />
            <Tooltip title={strings.LABEL_LOCALS_NAME_TOOLTIP}>
              <IconButton style={{ color: "white" }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {name && (
            <Grid item xs={8} mt={1}>
              <Typography color={errorName ? "error" : "success.main"}>
                {strings.LABEL_LOCALS_NAME_ERROR}
              </Typography>
            </Grid>
          )}
          <Grid item xs={8} mt={2} display="flex">
            <CustomTextField
              margin="normal"
              fullWidth
              id="label"
              label={strings.LABEL_LOCALS_ADDRESS}
              name="label"
              value={addressName}
              onChange={setAddressName}
              autoFocus
            />
            <Tooltip title={strings.LABEL_LOCALS_ADDRESS_TOOLTIP}>
              <IconButton style={{ color: "white" }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {addressName && (
            <Grid item xs={8} mt={1}>
              <Typography color={errorAddressName ? "error" : "success.main"}>
                {strings.LABEL_LOCALS_ADDRESS_ERROR}
              </Typography>
            </Grid>
          )}
          <Grid item xs={8} mt={2} display="flex">
            <CustomTextField
              margin="normal"
              fullWidth
              id="phone"
              label={strings.LABEL_LOCALS_PHONE}
              name="phone"
              value={phone}
              onChange={setPhone}
              autoFocus
            />
            <Tooltip title={strings.LABEL_LOCALS_PHONE_TOOLTIP}>
              <IconButton style={{ color: "white" }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {phone && (
            <Grid item xs={8} mt={1}>
              <Typography color={errorPhone ? "error" : "success.main"}>
                {strings.LABEL_LOCALS_PHONE_ERROR}
              </Typography>
            </Grid>
          )}
          <Grid item xs={8} mt={2} display="flex">
            <CustomTextField
              margin="normal"
              fullWidth
              id="address"
              label={strings.LABEL_LOCALS_ADDRESS_ID}
              name="address"
              value={addressId}
              onChange={setAddressId}
              autoFocus
            />
            <Tooltip title={strings.LABEL_LOCALS_ADDRESS_ID_TOOLTIP}>
              <IconButton style={{ color: "white" }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {addressId && (
            <Grid item xs={8} mt={1}>
              <Typography color={errorAddressId ? "error" : "success.main"}>
                {strings.LABEL_LOCALS_ADDRESS_ID_ERROR}
              </Typography>
            </Grid>
          )}

          <Grid item xs={8} mt={2} display="flex">
            <InfoIcon style={{ color: "white" }} />
            <Typography color={"white"}>
              {strings.LABEL_SETTINGS_SELECT_OPENING_HOURS}
            </Typography>
          </Grid>
          <Grid item xs={8} mt={2} display="flex">
            <Grid item xs={4} pr={1}>
              <CustomSelectField
                margin="normal"
                onChange={setDay}
                title={strings.LABEL_SETTINGS_DAY}
                id={"day"}
                value={day}
                defaultValues={listDays}
                defaultValue={1}
              ></CustomSelectField>
            </Grid>
            <Grid item xs={4} pr={1}>
              <CustomSelectField
                margin="normal"
                onChange={setFrom}
                title={strings.LABEL_SETTINGS_FROM}
                id={"day"}
                value={from}
                defaultValues={listHours}
                defaultValue={4}
              ></CustomSelectField>
            </Grid>
            <Grid item xs={3}>
              <CustomSelectField
                margin="normal"
                onChange={setTo}
                title={strings.LABEL_SETTINGS_TO}
                id={"day"}
                value={to}
                defaultValues={listHours}
                defaultValue={4}
              ></CustomSelectField>
            </Grid>
            <Grid item xs={1} display="flex" style={{ alignItems: "center" }}>
              <IconButton onClick={addRange}>
                <AddIcon style={{ color: "white" }} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={8} mt={2} mb={2}>
            {Object.entries(opening).map(([key, value]) => {
              let day = dayjs().startOf("week").add(key, "day").format("dddd");
              if (opening[key].length)
                return (
                  <React.Fragment key={key}>
                    <Grid item xs={12} sx={{ pt: 1 }} display="flex">
                      <Typography color={"white"}>
                        {day.charAt(0).toUpperCase() + day.slice(1)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ pt: 1 }}>
                      {value.map((item, index) => (
                        <Chip
                          key={key + "-" + index}
                          sx={{
                            mr: theme.spacing(),
                          }}
                          color="secondary"
                          label={item.open + "h to " + item.close + "h"}
                          onDelete={() => {
                            deleteRange(index, key);
                          }}
                        />
                      ))}
                    </Grid>
                  </React.Fragment>
                );
            })}
          </Grid>
        </Grid>
      </Paper>
      <div
        style={{
          alignSelf: "flex-end",
          flexDirection: "column",
          display: "flex",
          marginLeft: 10,
        }}
      >
        <Fab color="error" aria-label="cancel" disabled={true}>
          <CancelIcon />
        </Fab>
        <Fab
          color="primary"
          aria-label="add"
          style={{
            marginTop: 10,
          }}
          onClick={save}
          disabled={!valid}
        >
          <SaveIcon />
        </Fab>
      </div>
    </>
  );
};

export default LocalsEdit;
