import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { GlobalStyles } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import DashboardUser from "./components/DashboardUser";
import DashboardAdmin from "./components/DashboardAdmin";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import Users from "./components/Users";
import LocalsList from "./components/Locals/List";
import LocalsEdit from "./components/Locals/Edit";
import Feedbacks from "./components/Feedbacks";
import Protected from "./components/Protected";
import CompanyEdit from "./components/Company/Edit";
import CompanyList from "./components/Company/List";
import Advertising from "./components/Advertising";
import NotificationList from "./components/Notification/List";
import NotificationEdit from "./components/Notification/Edit";
import Pricing from "./components/Pricing";
import { useSelector } from "react-redux";
import { grey } from "@mui/material/colors";
import "./styles/global.css";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#182B39",
    },
    secondary: {
      main: "#5DADE2",
    },
    background: {
      default: "#5DADE2",
    },
    warning: {
      main: "#E2925D",
    },
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
  },
  components: {
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true,
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: grey[300],
          },
        },
      },
    },
  },
});

function App() {
  const isAuthenticated = useSelector((state) => state.login.tokenAccess);
  const user = useSelector((state) => state.user.user);
  return (
    <div className="App">
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyles
          styles={{
            "@keyframes mui-auto-fill": { from: { display: "block" } },
            "@keyframes mui-auto-fill-cancel": { from: { display: "block" } },
          }}
        />
        <CssBaseline />
        <Routes>
          {isAuthenticated ? (
            <>
              <Route path="/" element={<Protected />}>
                <Route
                  index
                  element={user.admin ? <DashboardAdmin /> : <DashboardUser />}
                />
                {user.admin && (
                  <>
                    <Route path="/companies" element={<CompanyList />} />
                  </>
                )}
                <>
                  <Route path="/companies/edit" element={<CompanyEdit />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/locals" element={<LocalsList />} />
                  <Route path="/locals/edit" element={<LocalsEdit />} />
                  <Route path="/feedbacks" element={<Feedbacks />} />
                  <Route path="/pricing" element={<Pricing />} />
                  <Route path="/advertising" element={<Advertising />} />
                  <Route path="/notifications" element={<NotificationList />} />
                  <Route
                    path="/notification/edit"
                    element={<NotificationEdit />}
                  />
                </>
              </Route>
              {/* Add a default route for unmatched paths */}
              <Route path="*" element={<Navigate to="/" />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Login />} />
              <Route path="/forgot" element={<ForgotPassword />} />
              <Route path="/forgot/:token" element={<ForgotPassword />} />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          )}
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
