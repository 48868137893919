// prettier-ignore
let dictionary = {
  ERROR_ENTER_NAME:'Please enter your name',
  ERROR_ENTER_PASSWORD:'Please enter your password',
  ERROR_USER_NOT_FOUND:'User not found, please verify your credentials',
  LABEL_ADVERTISING_AGE:'Age',
  LABEL_ADVERTISING_ALERT_PICTURE:'Please confirm you want to remove your picture',
  LABEL_ADVERTISING_CITY:'City',
  LABEL_ADVERTISING_COUNTRY:'Country',
  LABEL_ADVERTISING_GENDER:'Gender',
  LABEL_ADVERTISING_REGION:'Region',
  LABEL_ADVERTISING_TITLE_PICTURE:'Remove picture',
  LABEL_ADVERTISING_WARNING_CHOOSE:'You should choose an option',
  LABEL_ADVERTISING_WARNING_LOAD_IMAGE:'Please upload an image',
  LABEL_ADVERTISING_WEBSITE:'Website',
  LABEL_BUTTON_BACK:'Back',
  LABEL_BUTTON_CANCEL:'Cancel',
  LABEL_BUTTON_CONFIRM:'Confirm',
  LABEL_BUTTON_CONTACT:'Contact Us',
  LABEL_BUTTON_DELETE:'Delete',
  LABEL_BUTTON_RETRIEVE_PASSWORD: 'Retrieve password',
  LABEL_BUTTON_SAVE:'Save',
  LABEL_BUTTON_SIGNIN:'Sign in',
  LABEL_COMPANIES_ALERT_DELETE_CONTENT: 'You are about to delete this business, please confirm',
  LABEL_COMPANIES_ALERT_DELETE_TITLE: 'Delete a business',
  LABEL_COMPANIES_CHANGE_PICTURE: 'Change image',
  LABEL_COMPANIES_CODE_ERROR: 'Code must be 3 uppercase letters followed by 4 digits',
  LABEL_COMPANIES_ERROR_IMAGE_SIZE: 'Your image must be at least {0}px long x {1}px high; the size of the image you indicated is {2}px long x {3}px high',
  LABEL_COMPANIES_FEEDBACK: 'Feedback',
  LABEL_COMPANIES_FEEDBACK_TYPE: 'Determines if feedback is activated',
  LABEL_COMPANIES_GIFT_EXPIRATION_ERROR: 'It must be a number greater than 3 days',
  LABEL_COMPANIES_HEADER_ACTION: 'Action',
  LABEL_COMPANIES_HEADER_CODE: 'Code',
  LABEL_COMPANIES_HEADER_ID: 'Id',
  LABEL_COMPANIES_HEADER_NAME: 'Business name',
  LABEL_COMPANIES_HEADER_NUM_SCAN: 'Number of scans',
  LABEL_COMPANIES_HEADER_TYPE: 'Type',
  LABEL_COMPANIES_NAME_COMPANY_PICTURE: 'The name of your company',
  LABEL_COMPANIES_NAME_ERROR: 'The name must be between 5 and 60 characters and contain only letters, numbers, or spaces',
  LABEL_COMPANIES_NO_PICTURE: 'No image',
  LABEL_COMPANIES_PICTURE_MINIMUM: 'Please upload an image (minimum {0}px long x {1}px high)',
  LABEL_COMPANIES_POINT_EXPIRATION_ERROR: 'It must be a number greater than 3 days',
  LABEL_COMPANIES_PRIVATE: 'Private',
  LABEL_COMPANIES_PUBLIC: 'Public',
  LABEL_COMPANIES_REQUIREMENT_ERROR: 'Indicate only letters, numbers, or spaces',
  LABEL_COMPANIES_SEARCH: 'Search businesses',
  LABEL_COMPANIES_TOOLTIP_CODE: 'Indicate the code for this business.',
  LABEL_COMPANIES_TOOLTIP_FACEBOOK: 'Indicate your Facebook page in this format: https://www.facebook.com/Fidelize',
  LABEL_COMPANIES_TOOLTIP_GIFT_EXPIRATION: 'Indicate how long your clients will have to redeem their gift',
  LABEL_COMPANIES_TOOLTIP_INSTAGRAM: 'Indicate your Instagram page in this format: https://instagram.com/fidelize.chile',
  LABEL_COMPANIES_TOOLTIP_NAME: 'Choose a name for your company; this name will be used throughout the Fidelize network',
  LABEL_COMPANIES_TOOLTIP_POINT_EXPIRATION: 'Indicate the inactivity period necessary to lose a point.',
  LABEL_COMPANIES_TOOLTIP_REQUIREMENT: 'Indicate the condition for accumulating a point.',
  LABEL_COMPANIES_TOOLTIP_TYPE: 'Determines if the company will be visible in the app list',
  LABEL_COMPANIES_URL_FACEBOOK_ERROR: 'Please indicate a link in this format: https://www.facebook.com/Fidelize',
  LABEL_COMPANIES_URL_INSTAGRAM_ERROR: 'Please indicate a link in this format: https://www.instagram.com/Fidelize',
  LABEL_CONNECTED_AS: 'You are connected as {0}',
  LABEL_DASHBOARD_ACTIVE_CLIENTS: 'Active Clients',
  LABEL_DASHBOARD_ACTIVE_CLIENTS_INFO: 'Active clients in the last month',
  LABEL_DASHBOARD_AGE_DISTRIBUTION:'Age Distribution',
  LABEL_DASHBOARD_GENDER_DISTRIBUTION:'Gender Distribution',
  LABEL_DASHBOARD_GIFTS_GIVEN_W:'Gifts Given (Weekly)',
  LABEL_DASHBOARD_MAN:'Male',
  LABEL_DASHBOARD_NEW_CLIENTS: 'New Clients',
  LABEL_DASHBOARD_NEW_CLIENTS_INFO: 'New clients in the last month',
  LABEL_DASHBOARD_NOT_DEFINED:'Not defined',
  LABEL_DASHBOARD_NO_DATA:'No data',
  LABEL_DASHBOARD_NUMBER_OF_CLIENTS:'Number of Clients',
  LABEL_DASHBOARD_NUM_USUARIOS: 'Number of users',
  LABEL_DASHBOARD_NUM_USUARIOS_INFO: 'Total number of users from the beginning',
  LABEL_DASHBOARD_POINTS_GIVEN:'Points Given',
  LABEL_DASHBOARD_POINTS_GIVEN_W:'Points Given (Weekly)',
  LABEL_DASHBOARD_TOTAL_CLIENTS: 'Total clients',
  LABEL_DASHBOARD_TOTAL_CLIENTS_INFO: 'Total clients from the beginning',
  LABEL_DASHBOARD_W:'W',
  LABEL_DASHBOARD_WOMAN:'Woman',
  LABEL_FEEDBACKS_HEADER_DATE:'Date',
  LABEL_FEEDBACKS_HEADER_MESSAGE:'Message',
  LABEL_FEEDBACKS_HEADER_NOTE:'Note',
  LABEL_FEEDBACKS_HEADER_USER:'User Name',
  LABEL_FEEDBACKS_SEARCH:'Search feedbacks',
  LABEL_IMAGE_REQUIREMENT:'You image should have ration 2/3 (portrait) or 3/2 (landscape) and less than 2MB',
  LABEL_INPUT_ANSWER: 'Answer',
  LABEL_INPUT_CARD_EXPIRATION:'Card Expiration (days)',
  LABEL_INPUT_CODE: 'Code',
  LABEL_INPUT_FACEBOOK_URL:'Facebook URL',
  LABEL_INPUT_GIFT_EXPIRATION:'Gift Expiration (days)',
  LABEL_INPUT_IMAGE:'Image',
  LABEL_INPUT_INSTAGRAM_URL:'Instagram URL',
  LABEL_INPUT_MAIL: 'Email',
  LABEL_INPUT_NAME:'Name',
  LABEL_INPUT_NO_IMAGE:'There is no image',
  LABEL_INPUT_PASSWORD:'Password',
  LABEL_INPUT_REQUIREMENT: 'Requirement',
  LABEL_INPUT_USERNAME:'Username',
  LABEL_LOCALS_ADDRESS: 'Address',
  LABEL_LOCALS_ADDRESS_ERROR: 'The address must be between 5 and 60 characters and contain only letters, numbers, or spaces',
  LABEL_LOCALS_ADDRESS_ID: 'Address Id',
  LABEL_LOCALS_ADDRESS_ID_ERROR: 'It must be a Google Maps ID, for example AKHwGeGYTcMbo5Hn6',
  LABEL_LOCALS_ADDRESS_ID_TOOLTIP: 'Retrieve the address id from the Google page',
  LABEL_LOCALS_ADDRESS_TOOLTIP: 'The address as it will appear in the app',
  LABEL_LOCALS_ALERT_DELETE_CONTENT: 'You are about to delete this address, please confirm',
  LABEL_LOCALS_ALERT_DELETE_TITLE: 'Delete an address',
  LABEL_LOCALS_HEADER_ACTIONS: 'Action',
  LABEL_LOCALS_HEADER_ADDRESS:'Address',
  LABEL_LOCALS_HEADER_NAME:'Display Name',
  LABEL_LOCALS_HEADER_PHONE_NUMBER:'Phone Number',
  LABEL_LOCALS_NAME: 'Name',
  LABEL_LOCALS_NAME_ERROR: 'The name must be between 5 and 30 characters and contain only letters, numbers, or spaces',
  LABEL_LOCALS_NAME_TOOLTIP: 'The name that will be displayed in the app for this local',
  LABEL_LOCALS_PHONE: 'Phone',
  LABEL_LOCALS_PHONE_ERROR: 'The contact phone must be in the format tel:973123570 or whatsapp:973123570',
  LABEL_LOCALS_PHONE_TOOLTIP: 'The contact phone for this local, please use whatsapp: or tel:',
  LABEL_LOCALS_SEARCH:'Search locals',
  LABEL_NO: 'No',
  LABEL_NOTIFICATION_ACTION:'Action',
  LABEL_NOTIFICATION_ALERT_DELETE_CONTENT:'You will remove this notification, please confirm',
  LABEL_NOTIFICATION_ALERT_DELETE_TITLE:'Remove a notification',
  LABEL_NOTIFICATION_ANSWER:'Answer',
  LABEL_NOTIFICATION_ANSWER_DESC:'Indicate possibles answer',
  LABEL_NOTIFICATION_ANSWER_ERROR:'Debe indicar entre 2 y 5 respuestas (con 5 hasta 20 caracteres)',
  LABEL_NOTIFICATION_CONTENT:'Content',
  LABEL_NOTIFICATION_CONTENT_DESC:'Then write the content',
  LABEL_NOTIFICATION_CONTENT_ERROR:'El contenido debe ser entre 10 y 100 caracteres y contener solo letras, cifras o espacios',
  LABEL_NOTIFICATION_CREATED: 'Notification has been created',
  LABEL_NOTIFICATION_DATE:'Date',
  LABEL_NOTIFICATION_DATE_DESC:'And select the date and hour you want send it',
  LABEL_NOTIFICATION_DATE_ERROR:'Please select a date and time to send notifications',
  LABEL_NOTIFICATION_NO_DATE: 'No Date',
  LABEL_NOTIFICATION_NO_NOTIFICATION:' There is no notifications, please create your first one',
  LABEL_NOTIFICATION_STANDARD:'Standard',
  LABEL_NOTIFICATION_STATUS:'Status',
  LABEL_NOTIFICATION_SURVEY:'Survey',
  LABEL_NOTIFICATION_TITLE:'Title',
  LABEL_NOTIFICATION_TITLE_DESC:'Please choose a title for the notification',
  LABEL_NOTIFICATION_TITLE_ERROR:'El titulo debe ser entre 5 y 60 caracteres y contener solo letras cifras o espacios',
  LABEL_NOTIFICATION_TOOLTIP_ANSWER: 'Press enter/return key to add your answer',
  LABEL_NOTIFICATION_TOOLTIP_DATE: 'You cannot choose in the next hour, please indicate something after',
  LABEL_NOTIFICATION_TOOLTIP_TYPE: 'Type standard is a simple notification message, survey are questions and you will need to indicate possible answers',
  LABEL_NOTIFICATION_TYPE:'Type',
  LABEL_NOTIFICATION_UPDATED: 'Notification has been updated',
  LABEL_NO_RESULTS: 'No results',
  LABEL_PRICING_BASIC_FEATURE_1:'Access to the administration module',
  LABEL_PRICING_BASIC_FEATURE_2:'Manage your company settings',
  LABEL_PRICING_BASIC_FEATURE_3:'Manage your users (max 1 user)',
  LABEL_PRICING_BASIC_FEATURE_4:'Visualize your feedbacks',
  LABEL_PRICING_BASIC_FEATURE_5:'Basic support',
  LABEL_PRICING_BASIC_TITLE:'Plan Basic',
  LABEL_PRICING_CHOSE_PLAN:'Choose your monthly plan',
  LABEL_PRICING_OBJECT_INTERESTED:'Interested in',
  LABEL_PRICING_PREMIUM_FEATURE_1:'Include standard plan',
  LABEL_PRICING_PREMIUM_FEATURE_2:'Up to 3 Local',
  LABEL_PRICING_PREMIUM_FEATURE_3:'4 Notifications',
  LABEL_PRICING_PREMIUM_FEATURE_4:'1 Survey',
  LABEL_PRICING_PREMIUM_FEATURE_5:'Advanced analytics (Gender & Age distribution)',
  LABEL_PRICING_PREMIUM_FEATURE_6:'Advertising on Fidelize network up to 2000 impressions',
  LABEL_PRICING_PREMIUM_FEATURE_7:'Priority support',
  LABEL_PRICING_PREMIUM_TITLE:'Plan Premium',
  LABEL_PRICING_SCALED_PLAN:'Scaled plan',
  LABEL_PRICING_STANDARD_FEATURE_1:'Include basic plan',
  LABEL_PRICING_STANDARD_FEATURE_2:'Number of users unlimited',
  LABEL_PRICING_STANDARD_FEATURE_3:'Up to 1 Local',
  LABEL_PRICING_STANDARD_FEATURE_4:'1 Notification',
  LABEL_PRICING_STANDARD_FEATURE_5:'Basic analytics (Points, Gifts, Active clients, New clients)',
  LABEL_PRICING_STANDARD_FEATURE_6:'Advertising on Fidelize network up to 1000 impressions',
  LABEL_PRICING_STANDARD_FEATURE_7:'Standard support',
  LABEL_PRICING_STANDARD_TITLE:'Plan Standard',
  LABEL_RESET_DEFINE_DONE: 'The password has been successfully changed; we will redirect you to log in',
  LABEL_RESET_DEFINE_PASSWORD_CONTENT: 'Please provide your new password and repeat it',
  LABEL_RESET_DEFINE_PASSWORD_TITLE: 'Define new password',
  LABEL_RESET_ERROR_VERIFY: 'Please verify the fields',
  LABEL_RESET_GLOBAL_ERROR: 'There was an error in the process; please try again',
  LABEL_RESET_MAIL_NOT_FOUND: 'We did not find this email; please check your details',
  LABEL_RESET_PASSWORD_1: 'New password',
  LABEL_RESET_PASSWORD_1_ERROR: 'Your password must be at least 6 characters long and contain at least one letter, one number, and one special character',
  LABEL_RESET_PASSWORD_2: 'Password confirmation',
  LABEL_RESET_PASSWORD_2_ERROR: 'Please repeat your new password',
  LABEL_RESET_PASSWORD_ANSWER_ERROR: 'Please provide the correct answer',
  LABEL_RESET_PASSWORD_CONTENT: 'No problem, just provide your email and we will send you a link to reset it',
  LABEL_RESET_PASSWORD_MAIL_ERROR: 'Please provide a valid email',
  LABEL_RESET_PASSWORD_RESET_DONE: 'We have received your request to reset your password; check your emails to continue the process',
  LABEL_RESET_PASSWORD_RESOLVE: 'Please confirm you are human by answering this question',
  LABEL_RESET_PASSWORD_TITLE: 'Forgot your password?',
  LABEL_RESET_PASSWORD_TOOLTIP_1: 'Please provide your new password',
  LABEL_RESET_PASSWORD_TOOLTIP_2: 'Please repeat your new password',
  LABEL_RESET_QUESTION_COMPARE: 'Which is the larger number between {0} and {1}?',
  LABEL_RESET_QUESTION_SUM: 'What is the result of {0} + {1}?',
  LABEL_USERS_HEADER_ACTIONS:'Action',
  LABEL_USERS_HEADER_CONNECTIONS:'Connections',
  LABEL_USERS_HEADER_DOB: 'Date of birth',
  LABEL_USERS_HEADER_GENDER: 'Gender',
  LABEL_USERS_HEADER_NAME:'Display Name',
  LABEL_USERS_HEADER_PLATFORM:'Platform',
  LABEL_USERS_HEADER_STATUS: 'Status',
  LABEL_USERS_SEARCH:'Search users',
  LABEL_WARNING_ADVERTISING:'Please fill the advertising form to activate the functionality',
  LABEL_YES: 'Yes',
};
export default dictionary;
