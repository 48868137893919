import { COMPANY } from "../constants";
import { instance } from "../../axios";

let updateCompanyAction = (formData, id) => {
  return (dispatch) => {
    dispatch({
      type: COMPANY.CREATE_COMPANY,
    });
    instance
      .post("/company/" + id, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((results) => {
        dispatch({
          type: COMPANY.CREATE_COMPANY_SUCCESS,
          payload: results.data,
        });
        dispatch(getCompaniesAction());
      })
      .catch((error) => {
        dispatch({
          type: COMPANY.CREATE_COMPANY_FAILURE,
          payload: error,
        });
      });
  };
};

let createCompanyAction = (formData) => {
  return (dispatch) => {
    dispatch({
      type: COMPANY.CREATE_COMPANY,
    });
    instance
      .post("/company", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((results) => {
        dispatch({
          type: COMPANY.CREATE_COMPANY_SUCCESS,
          payload: results.data,
        });
        dispatch(getCompaniesAction());
      })
      .catch((error) => {
        dispatch({
          type: COMPANY.CREATE_COMPANY_FAILURE,
          payload: error,
        });
      });
  };
};

let getCompaniesAction = () => {
  return (dispatch) => {
    dispatch({
      type: COMPANY.GET_COMPANIES,
    });
    instance
      .get("/company")
      .then((results) => {
        dispatch({
          type: COMPANY.GET_COMPANIES_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: COMPANY.GET_COMPANIES_FAILURE,
          payload: error,
        });
      });
  };
};

let getCompanyAction = (id) => {
  return (dispatch) => {
    dispatch({
      type: COMPANY.GET_COMPANY,
    });
    instance
      .get("/company/" + id)
      .then((results) => {
        dispatch({
          type: COMPANY.GET_COMPANY_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: COMPANY.GET_COMPANY_FAILURE,
          payload: error,
        });
      });
  };
};

let deleteCompanyAction = (id) => {
  return (dispatch) => {
    dispatch({
      type: COMPANY.GET_COMPANY,
    });
    instance
      .delete("/company/" + id)
      .then((results) => {
        dispatch({
          type: COMPANY.GET_COMPANY_SUCCESS,
          payload: results.data,
        });
        dispatch(getCompaniesAction());
      })
      .catch((error) => {
        dispatch({
          type: COMPANY.GET_COMPANY_FAILURE,
          payload: error,
        });
      });
  };
};

let cleanCompanyAction = () => {
  return (dispatch) => {
    dispatch({
      type: COMPANY.CLEAN_COMPANY,
    });
  };
};

export {
  getCompanyAction,
  getCompaniesAction,
  cleanCompanyAction,
  createCompanyAction,
  updateCompanyAction,
  deleteCompanyAction,
};
