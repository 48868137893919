import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Fab from "@mui/material/Fab";
import LinearProgress from "@mui/material/LinearProgress";
import {
  getCompaniesAction,
  cleanCompanyAction,
  getCompanyAction,
} from "../../redux/actions/company";
import { updateSelectedCompanyAction } from "../../redux/actions/login";
import strings from "../../i18n/language";
import DeleteIcon from "@mui/icons-material/Delete";
import LoginIcon from "@mui/icons-material/Login";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CompanyDelete from "../../dialog/CompanyDelete";

const CompanyList = () => {
  const companies = useSelector((state) => state.company.companies);
  const [companyDeleteId, setCompanyDeleteId] = useState(null);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const dispatch = useDispatch();
  const companiesRef = useRef([]);
  const navigate = useNavigate();
  const isLoadingGetCompanies = useSelector(
    (state) => state.company.isLoadingGetCompanies
  );

  useEffect(() => {
    dispatch(getCompaniesAction());
  }, [dispatch]);

  useEffect(() => {
    if (companies) companiesRef.current = companies;
  }, [companies]);

  return (
    <>
      <Paper sx={{ p: 2, width: "80%" }}>
        {isLoadingGetCompanies ||
          (!companies && (
            <LinearProgress
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                width: "100%",
              }}
            />
          ))}
        {companies && (
          <TableContainer>
            <Table aria-label="Companies table">
              <TableHead>
                <TableRow>
                  <TableCell>{strings.LABEL_COMPANIES_HEADER_ID}</TableCell>
                  <TableCell>{strings.LABEL_COMPANIES_HEADER_NAME}</TableCell>
                  <TableCell>{strings.LABEL_COMPANIES_HEADER_TYPE}</TableCell>
                  <TableCell>
                    {strings.LABEL_COMPANIES_HEADER_NUM_SCAN}
                  </TableCell>
                  <TableCell>{strings.LABEL_COMPANIES_HEADER_CODE}</TableCell>
                  <TableCell>{strings.LABEL_COMPANIES_HEADER_ACTION}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {companies.map((company) => (
                  <TableRow key={company.id}>
                    <TableCell component="th" scope="row">
                      {company.id}
                    </TableCell>
                    <TableCell>{company.name}</TableCell>
                    <TableCell>
                      {company.public ? "Public" : "Private"}
                    </TableCell>
                    <TableCell>{company.activityCount}</TableCell>
                    <TableCell>{company.code}</TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          setCompanyDeleteId(company.id);
                          setOpenModalDelete(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          dispatch(getCompanyAction(company.id));
                          navigate("/companies/edit");
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="connect-as"
                        onClick={() => {
                          dispatch(updateSelectedCompanyAction(company));
                        }}
                      >
                        <LoginIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <Fab
        style={{ alignSelf: "flex-end", marginLeft: 10 }}
        color="primary"
        aria-label="add"
        onClick={() => {
          dispatch(cleanCompanyAction());
          navigate("/companies/edit");
        }}
      >
        <AddIcon />
      </Fab>
      <CompanyDelete
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        companyDeleteId={companyDeleteId}
      ></CompanyDelete>
    </>
  );
};

export default CompanyList;
