import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Card, CardContent } from "@mui/material";
import {
  ResponsiveContainer,
  Tooltip,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  CartesianGrid,
} from "recharts";
import { getStatsCompanyAction } from "../redux/actions/stats";
import { useDispatch, useSelector } from "react-redux";
import strings from "../i18n/language";
import InfoIcon from "@mui/icons-material/Info";

const pastelColors = ["#4E79A7", "#F28E2B", "#E15759", "#76B7B2", "#59A14F"];

function getPastelColorForNumber(num) {
  const colorIndex = num % pastelColors.length;
  return pastelColors[colorIndex];
}

const DashboardAdmin = () => {
  const dispatch = useDispatch();
  const stats = useSelector((state) => state.stats.stats);
  const [listCompanies, setListCompanies] = useState([]);
  const [dataPointByWeek, setDataPointByWeek] = useState([]);

  useEffect(() => {
    if (!stats) dispatch(getStatsCompanyAction());
    else {
      let listResult = [];
      let listData = [];
      Object.entries(stats.pointsGivenByWeek.listCompanies).map(
        ([key, value]) => {
          listResult.push({
            dataKey: value.name,
            stroke: getPastelColorForNumber(key),
            fill: getPastelColorForNumber(key),
          });
          return true;
        }
      );
      Object.entries(stats.pointsGivenByWeek.data).map(([week, data]) => {
        let item = {
          name: "Week " + week,
        };
        Object.entries(data).map(([idCompany, points]) => {
          item[stats.pointsGivenByWeek.listCompanies[idCompany].name] = points;
          return true;
        });
        listData.push(item);
        return true;
      });
      setListCompanies(listResult);
      console.log(listData);
      setDataPointByWeek(listData);
    }
  }, [stats, dispatch]);

  if (!stats) return null;
  else
    return (
      <Box sx={{ flexGrow: 1, mx: 2 }}>
        <Grid container spacing={3}>
          {/* Number of Active Client Card */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  {strings.LABEL_DASHBOARD_TOTAL_CLIENTS}
                </Typography>
                <Typography variant="h3" component="div">
                  {stats.numberOfCompanies}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <InfoIcon fontSize="small" />
                  <Typography variant="body2" component="div">
                    {strings.LABEL_DASHBOARD_TOTAL_CLIENTS_INFO}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          {/* Number of Clients Card */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  {strings.LABEL_DASHBOARD_NUM_USUARIOS}
                </Typography>
                <Typography variant="h3" component="div">
                  {stats.numberOfUsers}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <InfoIcon fontSize="small" />
                  <Typography variant="body2" component="div">
                    {strings.LABEL_DASHBOARD_NUM_USUARIOS_INFO}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          {/* Number of Clients Card */}
          <Grid item xs={12} md={12}>
            <Card>
              <CardContent>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {strings.LABEL_DASHBOARD_POINTS_GIVEN_W}
                  </Typography>
                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                      data={dataPointByWeek}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      {listCompanies.map((config, index) => (
                        <Bar
                          dataKey={config.dataKey}
                          stackId="1"
                          fill={config.fill}
                        />
                      ))}
                    </BarChart>
                  </ResponsiveContainer>
                </CardContent>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    );
};

export default DashboardAdmin;
