export const LOGIN = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT: "LOGOUT",
  UPDATE_TOKEN: "UPDATE_TOKEN",
  UPDATE_SELECTED_COMPANY: "UPDATE_SELECTED_COMPANY",
  CLEAN_ERROR: "CLEAN_ERROR",
};

export const USER = {
  SET_USER: "SET_USER",
  CLEAN_USER: "CLEAN_USER",
  GET_USERS: "GET_USERS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  DEFINE_PASSWORD: "DEFINE_PASSWORD",
  DEFINE_PASSWORD_FAILURE: "DEFINE_PASSWORD_FAILURE",
  DEFINE_PASSWORD_SUCCESS: "DEFINE_PASSWORD_SUCCESS",
  CLEAN_ERROR: "CLEAN_ERROR",
  RESET: "RESET",
};

export const COMPANY = {
  CREATE_COMPANY: "CREATE_COMPANY",
  CREATE_COMPANY_FAILURE: "CREATE_COMPANY_FAILURE",
  CREATE_COMPANY_SUCCESS: "CREATE_COMPANY_SUCCESS",
  UPDATE_COMPANY: "UPDATE_COMPANY",
  UPDATE_COMPANY_FAILURE: "UPDATE_COMPANY_FAILURE",
  UPDATE_COMPANY_SUCCESS: "UPDATE_COMPANY_SUCCESS",
  DELETE_COMPANY: "DELETE_COMPANY",
  DELETE_COMPANY_FAILURE: "DELETE_COMPANY_FAILURE",
  DELETE_COMPANY_SUCCESS: "DELETE_COMPANY_SUCCESS",
  GET_COMPANY: "GET_COMPANY",
  GET_COMPANY_FAILURE: "GET_COMPANY_FAILURE",
  GET_COMPANY_SUCCESS: "GET_COMPANY_SUCCESS",
  GET_COMPANIES: "GET_COMPANIES",
  GET_COMPANIES_SUCCESS: "GET_COMPANIES_SUCCESS",
  GET_COMPANIES_FAILURE: "GET_COMPANIES_FAILURE",
  CLEAN_COMPANY: "CLEAN_COMPANY",
};

export const ADDRESS = {
  GET_ADDRESS: "GET_ADDRESS",
  GET_ADDRESS_FAILURE: "GET_ADDRESS_FAILURE",
  GET_ADDRESS_SUCCESS: "GET_ADDRESS_SUCCESS",
  GET_ADDRESSES: "GET_ADDRESSES",
  GET_ADDRESSES_FAILURE: "GET_ADDRESSES_FAILURE",
  GET_ADDRESSES_SUCCESS: "GET_ADDRESSES_SUCCESS",
  DELETE_ADDRESS: "DELETE_ADDRESS",
  DELETE_ADDRESS_FAILURE: "DELETE_ADDRESS_FAILURE",
  DELETE_ADDRESS_SUCCESS: "DELETE_ADDRESS_SUCCESS",
  CREATE_ADDRESS: "CREATE_ADDRESS",
  CREATE_ADDRESS_FAILURE: "CREATE_ADDRESS_FAILURE",
  CREATE_ADDRESS_SUCCESS: "CREATE_ADDRESS_SUCCESS",
  UPDATE_ADDRESS: "UPDATE_ADDRESS",
  UPDATE_ADDRESS_FAILURE: "UPDATE_ADDRESS_FAILURE",
  UPDATE_ADDRESS_SUCCESS: "UPDATE_ADDRESS_SUCCESS",
  CLEAN_ADDRESS: "CLEAN_ADDRESS",
};

export const FEEDBACK = {
  GET_FEEDBACK: "GET_FEEDBACK",
  GET_FEEDBACK_FAILURE: "GET_FEEDBACK_FAILURE",
  GET_FEEDBACK_SUCCESS: "GET_FEEDBACK_SUCCESS",
};

export const STATS = {
  GET_STATS_USER: "GET_STATS_USER",
  GET_STATS_USER_FAILURE: "GET_STATS_USER_FAILURE",
  GET_STATS_USER_SUCCESS: "GET_STATS_USER_SUCCESS",
  GET_STATS_COMPANY: "GET_STATS_COMPANY",
  GET_STATS_COMPANY_FAILURE: "GET_STATS_COMPANY_FAILURE",
  GET_STATS_COMPANY_SUCCESS: "GET_STATS_COMPANY_SUCCESS",
  CLEAN_STATS: "CLEAN_STATS",
};

export const ADVERTISING = {
  POST_ADVERTISING: "POST_ADVERTISING",
  POST_ADVERTISING_FAILURE: "POST_ADVERTISING_FAILURE",
  POST_ADVERTISING_SUCCESS: "POST_ADVERTISING_SUCCESS",
  GET_ADVERTISING: "GET_ADVERTISING",
  GET_ADVERTISING_FAILURE: "GET_ADVERTISING_FAILURE",
  GET_ADVERTISING_SUCCESS: "GET_ADVERTISING_SUCCESS",
  CLEAN_ADVERTISING: "CLEAN_ADVERTISING",
  CLEAN_ADVERTISING_PICTURE: "CLEAN_ADVERTISING_PICTURE",
  CLEAN_ADVERTISING_PICTURE_FAILURE: "CLEAN_ADVERTISING_PICTURE_FAILURE",
  CLEAN_ADVERTISING_PICTURE_SUCCESS: "CLEAN_ADVERTISING_PICTURE_SUCCESS",
};

export const NOTIFICATIONS = {
  CREATE_NOTIFICATION: "CREATE_NOTIFICATION",
  CREATE_NOTIFICATION_FAILURE: "CREATE_NOTIFICATION_FAILURE",
  CREATE_NOTIFICATION_SUCCESS: "CREATE_NOTIFICATION_SUCCESS",
  UPDATE_NOTIFICATION: "UPDATE_NOTIFICATION",
  UPDATE_NOTIFICATION_FAILURE: "UPDATE_NOTIFICATION_FAILURE",
  UPDATE_NOTIFICATION_SUCCESS: "UPDATE_NOTIFICATION_SUCCESS",
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  GET_NOTIFICATIONS_FAILURE: "GET_NOTIFICATIONS_FAILURE",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  DELETE_NOTIFICATION: "DELETE_NOTIFICATION",
  DELETE_NOTIFICATION_FAILURE: "DELETE_NOTIFICATION_FAILURE",
  DELETE_NOTIFICATION_SUCCESS: "DELETE_NOTIFICATION_SUCCESS",
  CLEAN_NOTIFICATIONS: "CLEAN_NOTIFICATIONS",
  SET_NOTIFICATION: "SET_NOTIFICATION",
};
