import { USER } from "../constants";

const initialState = {
  user: null,
  users: null,
  isLoadingUser: true,
  isLoadingUsers: true,
  isLoadingUpdateUser: false,
  isLoadingUserReset: false,
  isLoadingUserDefine: false,
  resetPassword: false,
  definePassword: false,
  error: null,
};

const userReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case USER.SET_USER:
      return {
        ...state,
        isLoadingUser: false,
        user: action.payload,
      };
    case USER.CLEAN_USER:
      return {
        ...state,
        user: null,
      };
    case USER.GET_USERS:
      return {
        ...state,
        isLoadingUsers: true,
      };
    case USER.GET_USERS_FAILURE:
      return {
        ...state,
        isLoadingUsers: false,
        error: action.payload,
      };
    case USER.GET_USERS_SUCCESS:
      return {
        ...state,
        isLoadingUsers: false,
        users: action.payload,
        error: null,
      };
    case USER.UPDATE_USER:
      return {
        ...state,
        isLoadingUpdateUser: true,
      };
    case USER.UPDATE_USER_FAILURE:
      return {
        ...state,
        isLoadingUpdateUser: false,
        error: action.payload,
      };
    case USER.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoadingUpdateUser: false,
        user: action.payload,
        error: null,
      };
    case USER.RESET_PASSWORD:
      return {
        ...state,
        resetPassword: false,
        isLoadingUserReset: true,
      };
    case USER.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isLoadingUserReset: false,
        resetPassword: false,
        error: action.payload,
      };
    case USER.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoadingUserReset: false,
        resetPassword: true,
        error: null,
      };
    case USER.DEFINE_PASSWORD:
      return {
        ...state,
        isLoadingUserDefine: true,
        definePassword: false,
      };
    case USER.DEFINE_PASSWORD_FAILURE:
      return {
        ...state,
        isLoadingUserDefine: false,
        error: action.payload,
        definePassword: false,
      };
    case USER.DEFINE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoadingUserDefine: false,
        definePassword: true,
        error: null,
      };
    case USER.CLEAN_ERROR:
      return {
        ...state,
        error: null,
      };
    case USER.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default userReducer;
