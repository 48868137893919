import { USER } from "../constants";
import { instance, baseUrl } from "../../axios";

let getUsersAction = (companyId) => {
  return (dispatch) => {
    dispatch({
      type: USER.GET_USERS,
    });
    instance
      .get("/user?company_id=" + companyId)
      .then((results) => {
        dispatch({
          type: USER.GET_USERS_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: USER.GET_USERS_FAILURE,
          payload: error,
        });
      });
  };
};

let updateUserAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: USER.UPDATE_USER,
    });
    instance
      .post("/user/" + user.id, user)
      .then((results) => {
        dispatch({
          type: USER.UPDATE_USER_SUCCESS,
          payload: results.data,
        });
        dispatch(getUsersAction(results.data.company_id));
      })
      .catch((error) => {
        dispatch({
          type: USER.UPDATE_USER_FAILURE,
          payload: error,
        });
      });
  };
};

let resetUserPasswordAction = (body) => {
  return (dispatch) => {
    dispatch({
      type: USER.RESET_PASSWORD,
    });
    instance({
      method: "POST",
      url: baseUrl + "/user/reset",
      data: body,
    })
      .then((results) => {
        dispatch({
          type: USER.RESET_PASSWORD_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: USER.RESET_PASSWORD_FAILURE,
          payload: error,
        });
      });
  };
};

let definePasswordAction = (body) => {
  return (dispatch) => {
    dispatch({
      type: USER.DEFINE_PASSWORD,
    });
    instance({
      method: "POST",
      url: baseUrl + "/user/define",
      data: body,
    })
      .then((results) => {
        dispatch({
          type: USER.DEFINE_PASSWORD_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: USER.DEFINE_PASSWORD_FAILURE,
          payload: error,
        });
      });
  };
};

let setUserAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: USER.SET_USER,
      payload: user,
    });
  };
};

let cleanUserAction = () => {
  return (dispatch) => {
    dispatch({
      type: USER.CLEAN_USER,
    });
  };
};

let cleanErrorAction = () => {
  return (dispatch) => {
    dispatch({
      type: USER.CLEAN_ERROR,
    });
  };
};

let resetUserAction = () => {
  return (dispatch) => {
    dispatch({
      type: USER.RESET,
    });
  };
};

export {
  setUserAction,
  cleanUserAction,
  getUsersAction,
  updateUserAction,
  resetUserAction,
  definePasswordAction,
  cleanErrorAction,
  resetUserPasswordAction,
};
