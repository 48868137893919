import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import strings from "../i18n/language";
import React from "react";
import { deleteAddressAction } from "../redux/actions/address";

const AddressDelete = ({ open, setOpen, addressDeleteId }) => {
  const dispatch = useDispatch();
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="alert-dialog-delete-title"
      aria-describedby="alert-dialog-delete-description"
    >
      <DialogTitle id="alert-dialog-delete-title">
        {strings.LABEL_LOCALS_ALERT_DELETE_TITLE}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-delete-description">
          {strings.LABEL_LOCALS_ALERT_DELETE_CONTENT}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          {strings.LABEL_BUTTON_CANCEL}
        </Button>
        <Button
          onClick={() => {
            dispatch(deleteAddressAction(addressDeleteId));
            setOpen(false);
          }}
        >
          {strings.LABEL_BUTTON_CONFIRM}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddressDelete;
