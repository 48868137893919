import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Copyright } from "../Copyright";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/QueryStats";
import UsersIcon from "@mui/icons-material/People";
import LocalsIcon from "@mui/icons-material/Store";
import BusinessIcon from "@mui/icons-material/Business";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import FeedbacksIcon from "@mui/icons-material/ChatBubble";
import CampaignIcon from "@mui/icons-material/Campaign";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { getAdvertisingAction } from "../redux/actions/advertising";
import WarningIcon from "@mui/icons-material/Warning";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import strings from "../i18n/language";

import { Drawer } from "../ui/Drawer";
import AppBarComponent from "../ui/AppBar";

const Protected = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [showWarningAdvertising, setShowWarningAdvertising] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const advertising = useSelector((state) => state.advertising.advertising);
  const user = useSelector((state) => state.user.user);
  const selectedCompany = useSelector((state) => state.login.selectedCompany);
  const theme = useTheme();

  useEffect(() => {
    if (!advertising) {
      dispatch(getAdvertisingAction());
    } else {
      if (
        advertising.country &&
        advertising.region &&
        advertising.city &&
        advertising.website &&
        advertising.picture &&
        Object.keys(JSON.parse(advertising.genders)).some(
          (k) => JSON.parse(advertising.genders)[k] !== false
        ) &&
        Object.keys(JSON.parse(advertising.ages)).some(
          (k) => JSON.parse(advertising.ages)[k] !== false
        )
      ) {
        setShowWarningAdvertising(false);
      } else setShowWarningAdvertising(true);
    }
  }, [advertising, dispatch]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBarComponent open={open} toggleDrawer={toggleDrawer} />
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <List>
          <ListItemButton
            selected={location.pathname === "/"}
            onClick={() => navigate("/")}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
          {user.admin && (
            <ListItemButton
              selected={
                location.pathname === "/companies" ||
                location.pathname === "/companies/edit"
              }
              onClick={() => navigate("/companies")}
            >
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary="Companies" />
            </ListItemButton>
          )}
          {!user.admin && (
            <>
              <ListItemButton
                selected={location.pathname === "/company"}
                onClick={() => navigate("/companies/edit")}
              >
                <ListItemIcon>
                  <FingerprintIcon />
                </ListItemIcon>
                <ListItemText primary="My Company" />
              </ListItemButton>
            </>
          )}
          <ListItemButton
            disabled={!selectedCompany}
            selected={location.pathname === "/users"}
            onClick={() => navigate("/users")}
          >
            <ListItemIcon>
              <UsersIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItemButton>
          <ListItemButton
            disabled={!selectedCompany}
            selected={
              location.pathname === "/locals" ||
              location.pathname === "/locals/edit"
            }
            onClick={() => navigate("/locals")}
          >
            <ListItemIcon>
              <LocalsIcon />
            </ListItemIcon>
            <ListItemText primary="Locales" />
          </ListItemButton>
          <ListItemButton
            disabled={!(selectedCompany && selectedCompany.gift_feedback)}
            selected={location.pathname === "/feedbacks"}
            onClick={() => navigate("/feedbacks")}
          >
            <ListItemIcon>
              <FeedbacksIcon />
            </ListItemIcon>
            <ListItemText primary="Feedbacks" />
          </ListItemButton>
          <ListItemButton
            disabled={!selectedCompany}
            selected={location.pathname === "/pricing"}
            onClick={() => navigate("/pricing")}
          >
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText primary="Pricing" />
          </ListItemButton>
          <ListItemButton
            disabled={user.admin}
            selected={location.pathname === "/advertising"}
            onClick={() => navigate("/advertising")}
          >
            <ListItemIcon>
              <CampaignIcon />
            </ListItemIcon>
            <ListItemText primary="Advertising" />
            {showWarningAdvertising && (
              <ListItemIcon sx={{ color: theme.palette.warning.main }}>
                <Tooltip title={strings.LABEL_WARNING_ADVERTISING}>
                  <WarningIcon />
                </Tooltip>
              </ListItemIcon>
            )}
          </ListItemButton>
          <ListItemButton
            disabled={user.admin}
            selected={location.pathname === "/notifications"}
            onClick={() => navigate("/notifications")}
          >
            <ListItemIcon>
              <NotificationsIcon />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
          </ListItemButton>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: "#5DADE2",
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 8, mb: 4 }}>
          <Grid
            container
            spacing={3}
            sx={{
              justifyContent: "center",
            }}
          >
            <Outlet />
          </Grid>
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
      <ToastContainer position="bottom-right" />
    </Box>
  );
};

export default Protected;
